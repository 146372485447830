export default [
  /*format when entering a new upcoming event
{
  date: "Abbreviated-Month Day Year", //if "TBD", just add "TBD", else follow the format. For example: "Oct 13 2020"
  name: "Event Name",
  description: "Details/description of event"
  //the rest below is optional, if event have a link
  haveLink: boolean (true/false), //most likely true if the event have link
  link: "actual link",
  linkName: "name of link"
}*/
  {
    date: 'Nov 13 2020',
    name: 'Zurich National Apprenticeship Week Summit',
    description:
      'November 13. 9am CT – 11am CT \n ' +
      'Virtual event hosted by the commercial insurance provider aimed at graduating high school seniors, military vets, ' +
      'individuals returning to work after a hiatus and those seeking to move from a job to a career. Will include a discussion panel ' +
      'with current Zurich Apprentices, sharing insights about their roles and balancing work, school and life, as well as guidance on ' +
      'transitioning to a corporate environment. For more information about attending, contact ',
    haveLink: true,
    link: 'mailto:robert.pullion@zurichna.com',
    linkName: 'robert.pullion@zurichna.com.'
  },
  // {
  //   date: 'May 3',
  //   name: 'Spring 2023 Event',
  //   description:
  //     'We are excited that we will be spotlighting the Discovery Partners Institute (DPI) and the tech apprentice program ' +
  //     'they recently launched with SAP and other employers at this event! \n\n' +
  //     'Wednesday, May 3rd from 3:30 pm to 5:00 pm CST \n' +
  //     '(In-person only) \n\n' +
  //     'Discovery Partners Institute (DPI) Office\n' +
  //     '200 S. Wacker Dr 4th Floor\n' +
  //     'Chicago, IL 60606\n\n' +
  //     'We look forward to having you join us! \n\n',
  //   haveLink: true,
  //   link: 'https://aonpublicaffairs.wufoo.com/forms/chicago-apprentice-network-dpi/',
  //   linkName: 'Register'
  // },
  // {
  //   date: 'Nov 14',
  //   name: 'Fall 2023 Event',
  //   description:
  //     'We look forward to celebrating National Apprentice Week with you on November 14-20. Stay tuned for details! \n\n'
  // },
  // {
  //   date: 'Feb',
  //   name: 'Winter 2024 Event',
  //   description:
  //     'We look forward to our next event with Northwestern Medicine in February 2024 \n\n'
  // },
  // {
  //   date: 'TBD',
  //   name: 'Summer 2024 Event',
  //   description:
  //     'We look forward to our next event in the Summer of 2024'
  // },
  {
    date: 'TBD',
    name: 'Fall 2024 Event',
    description:
      'We look forward to our next event in the Fall of 2024'
  },
];
