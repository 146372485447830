import React from "react";
import "./style.css";

const Mission = props => {
  return (
    <div className="mission">
      <h1 className="headingUnderline">OUR MISSION</h1>
      <p>Talent is equally distributed. Opportunity is not.</p>
      <p>
        The <span className="missionBold">Chicago Apprentice Network</span> is
        working to bridge the gap by raising awareness of apprenticeship and
        helping more businesses establish successful apprentice programs to
        offer more opportunities to more people in Chicagoland.
      </p>
      <p>
        Since 2017, the Chicago Apprentice Network has grown from three founding
        companies – Accenture, Aon and Zurich North America – to more than 110
        companies across multiple industries.
      </p>
      <p>
        Our members include forward-thinking employers and apprentices who
        represent diverse backgrounds, experiences and perspectives. Together,
        we’re fueling innovation, inclusion and excitement in Chicago’s economy.{" "}
      </p>
      <p className="missionBold">
        Explore how apprenticeships can brighten our shared future.
      </p>
    </div>
  );
};

export default Mission;
